import React from "react";
import { Box, Typography } from "@mui/material";
import phoneImage from "../../assets/phone1.png";

const MobileSection = () => {
  return (
    <Box
      sx={{
        marginTop: { xs: "50px", sm: "75px", md: "50px" }, // Responsive margin
      }}
    >
      {" "}
      <Typography
        variant="h4"
        align="center"
        fontWeight={"600"}
        fontSize={"60px"}
        gutterBottom
      >
        Fund Usage
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          marginTop: "50px",
          // marginTop: { xs: "50px", sm: "75px", md: "100px" }, // Responsive margin
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: { xs: "500px", sm: "700px", md: "1163.55px" }, // Adjust width for extra small screens
            height: { xs: "400px", sm: "500px", md: "900px" }, // Adjust height for extra small screens
          }}
        >
          {/* Background Circle */}
          {/* <Box
            sx={{
              position: "absolute",
              width: { xs: "250px", sm: "300px", md: "500px" }, // Adjust circle size for smaller screens
              height: { xs: "250px", sm: "300px", md: "500px" },
              background: "radial-gradient(circle, #55b37c, #59c988)",
              borderRadius: "50%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: "0px 0px 150px #59c988",
            }}
          ></Box> */}
          <Box
            sx={{
              position: "absolute",
              width: { xs: "350px", sm: "500px", md: "848px" }, // Adjust circle size for smaller screens
              height: { xs: "350px", sm: "500px", md: "848px" },
              background:
                "radial-gradient(50% 50% at 50% 50%, #3CD99F 0%, rgba(152, 241, 208, 0.83902) 42.39%, rgba(89, 223, 174, 0.760445) 69.69%, rgba(0, 0, 0, 0.2) 100%)",
              borderRadius: "50%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // boxShadow: "0px 0px 150px rgba(89, 223, 174, 0.8)", // Adjusted shadow color slightly for the new background
            }}
          ></Box>

          {/* Mobile Image */}
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              backgroundImage: `url(${phoneImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></Box>

          {/* Content Box - Top Left */}
          {/* <Box
            sx={{
              position: "absolute",
              width: { xs: "165px", sm: "250px", md: "346px" }, // Adjust width responsively
              height: "115px",
              backgroundColor: "#ccc",
              borderRadius: "10px",
              top: { xxs: "50px", xs: "70px", sm: "110px", md: "180px" }, // Responsive positioning
              right: { xxs: "50px", xs: "116px", sm: "150px", md: "260px" }, // Responsive positioning
            }}
          ></Box> */}

          {/* Content Box - Top Right */}
          {/* <Box
            sx={{
              position: "absolute",
              width: { xs: "180px", sm: "250px", md: "346px" }, // Adjust width responsively
              height: "115px",
              backgroundColor: "#ccc",
              borderRadius: "10px",
              top: { xs: "150px", sm: "190px", md: "350px" }, // Adjust width responsively
              left: { xs: "100px", sm: "150px", md: "217px" }, // Adjust width responsively
            }}
          ></Box> */}

          {/* Content Box - Bottom Left */}
          {/* <Box
            sx={{
              position: "absolute",
              width: { xs: "165px", sm: "250px", md: "346px" }, // Adjust width responsively
              height: "115px",
              backgroundColor: "#ccc",
              borderRadius: "10px",
              bottom: { xs: "65px", sm: "100px", md: "150px" }, // Adjust width responsively
              right: { xs: "116px", sm: "150px", md: "220px" }, // Adjust width responsively
            }}
          ></Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default MobileSection;

// import React from "react";
// import './MobileSection.css';
// import '../assets/file.png'
// const MobileSection = () => {
//   return (
//     <div className="phone-section">
//       <div className="mobile-container">
//         <div className="background-circle"></div>
//         <div className="mobile-image"></div>
//         <div className="content-box top-left"></div>
//         <div className="content-box top-right"></div>
//         <div className="content-box bottom-left"></div>
//       </div>
//     </div>
//   );
// };

// export default MobileSection;
