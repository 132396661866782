import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import vectorImage from "../../assets/Vector.png";
import Vectorline from "../../assets/Vectorline.png";
const ContactPage = () => {
  return (
    <Box
      component="main"
      sx={{
        color: "#ffffff",
        // padding: { xs: "0px 20px", md: "0px 40px" },
      }}
    >
      <Box
        sx={{
          marginBottom: "100px",
          width: "100%",
          height: "588px",
          backgroundImage: `url(${vectorImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      <Box
        sx={{
          textAlign: "center",
          marginBottom: "100px",
          padding: { xs: "0px 20px", md: "0px 40px" },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#FFFFFF",
            fontWeight: "600",
            marginBottom: "66px",
            fontSize: { xs: "20px", sm: "30px", md: "34px" },
          }}
        >
          Our packages
        </Typography>
        <Box
          sx={{
            // position: "relative",
            width: { xs: "100%", sm: "100%", md: "100%" },
            height: "748.27px",
            backgroundColor: "GrayText",
            margin: { xs: "20px 0px", md: "40px 0px" },
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
            borderRadius: "31px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              paddingLeft: { xs: "10%", sm: "20%", md: "25%" },
            }}
          >
            <Box
              sx={{
                // marginBottom: "50px",
                width: "78.21px",
                height: "612.54px",
                backgroundImage: `url(${Vectorline})`,
                // backgroundSize: "cover",
                // backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                // paddingLeft: { xs: "46px", sm: "62px", md: "320px" },
              }}
            />
            <Box
              sx={{
                width: "78.21px",
                height: "612.54px",
                backgroundImage: `url(${Vectorline})`,
                // backgroundSize: "cover",
                // backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                // paddingLeft: { xs: "46px", sm: "62px", md: "250px" },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactPage;
