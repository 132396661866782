import React from "react";
import { Box, Typography } from "@mui/material";
import CardSection from "../../components/cardSection/CardSection";
import vectorImage from "../../assets/Vector.png";
import bgImage from "../../assets/bgimage.png";

const PlansPage = () => {
  return (
    <Box
      component="main"
      sx={{
        color: "#ffffff",
        // padding: { xs: "0px 20px", md: "0px 40px" },
      }}
    >
      <Box
        sx={{
          marginBottom: "50px",
          width: "100%",
          height: "588px",
          backgroundImage: `url(${vectorImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "end",
          justifyContent: "flex-start",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#FFFFFF",
            fontWeight: "600",
            fontSize: { xs: "20px", sm: "30px", md: "34px" },
            maxWidth: { xs: "40%", sm: "40%", md: "40%" },
            // paddingTop: "250px",
            paddingLeft: { xs: "46px", sm: "62px", md: "170px" },
          }}
        >
          Our packages
        </Typography>
      </Box>

      <Box
        sx={{
          textAlign: "center",
          marginBottom: "100px",
          display: "flex",
          justifyContent: "space-around",
          padding: { xs: "30px 50px", md: "50px 70px" },
          flexDirection: { xs: "column", sm: "column", md: "row" },
          rowGap: "50px",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "38%", md: "38%" },
            height: "645px",
            backgroundColor: "#D9D9D9",
          }}
        ></Box>

        <Box
          sx={{
            // width: "502px",
            width: { xs: "100%", sm: "38%", md: "38%" },
            height: "645px",
            backgroundColor: "#D9D9D9",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          width: { xs: "70px", sm: "90px", md: "100%" },
          height: { xs: "70px", sm: "90px", md: "750px" },
          // backgroundColor: "#D9D9D9",
          mb: "150px",
        }}
      >
        <Box
          component="img"
          src={bgImage}
          alt={`bg image`}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            opacity: '0.5'
          }}
        />
      </Box>

      <Box
        sx={{
          padding: { xs: "0px 20px", md: "0px 40px" },
        }}
      >
        <CardSection />
      </Box>
    </Box>
  );
};

export default PlansPage;
