import React from "react";
import { Grid, Box, Typography, Link } from "@mui/material";
import iconImage from "../../assets/icon.png";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Column for mobile, row for larger screens
        justifyContent: { md: "space-between" }, // Space between elements on larger screens
        color: "#ffffff",
        padding: { xs: "40px 20px", sm: "50px 40px", md: "70px" },
        height: { xs: "auto", md: "35vh" }, // Responsive height
        gap: { xs: "20px", md: "0" }, // Spacing for stacked layout on mobile
      }}
    >
      {/* Left Placeholder */}
      <Box
        sx={{
          width: { xs: "100%", sm: "35%" },
          display: { xs: "flex", md: "block" }, // Flex for centered on mobile
          justifyContent: "center",
          paddingLeft: { xs: "0", md: "55px" },
        }}
      >
        <Box
          sx={{
            width: { xs: "80%", sm: "245.93px" },
            height: { xs: "200px", sm: "254.84px" },
            backgroundImage: `url(${iconImage})`,
            backgroundSize: { xs: "contain", sm: "cover" },
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Box>

      {/* Footer Content */}
      <Grid
        container
        spacing={2}
        sx={{
          width: { xs: "100%", md: "60%" },
          margin: 0,
          rowGap: "10px",
        }}
      >
        {/* Info Section */}
        <Grid item xs={12} sm={4}>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "18px", sm: "20px", md: "22px" },
              fontWeight: "bold",
              mb: "10px",
            }}
          >
            INFO
          </Typography>
          <Box sx={{ listStyleType: "none", padding: 0 }}>
            {["Home", "Invest", "About Us", "Plans", "Contact Us"].map(
              (text, index) => (
                <li key={index} style={{ paddingBottom: "10px" }}>
                  {text === "Home" ? (
                    <Link
                      href="/"
                      color="inherit"
                      underline="hover"
                      sx={{
                        fontSize: { xs: "16px", md: "18px" },
                        fontWeight: "600",
                      }}
                    >
                      {text}
                    </Link>
                  ) : text === "About Us" ? (
                    <Link
                      href="/about"
                      color="inherit"
                      underline="hover"
                      sx={{
                        fontSize: { xs: "16px", md: "18px" },
                        fontWeight: "600",
                      }}
                    >
                      {text}
                    </Link>
                  ) : text === "Contact Us" ? (
                    <Link
                      href="/contact"
                      color="inherit"
                      underline="hover"
                      sx={{
                        fontSize: { xs: "16px", md: "18px" },
                        fontWeight: "600",
                      }}
                    >
                      {text}
                    </Link>
                  ) : (
                    <Link
                      href={`/${text.toLowerCase().replace(" ", "")}`}
                      color="inherit"
                      underline="hover"
                      sx={{
                        fontSize: { xs: "16px", md: "18px" },
                        fontWeight: "600",
                      }}
                    >
                      {text}
                    </Link>
                  )}
                </li>
              )
            )}
          </Box>
        </Grid>

        {/* Useful Links Section */}
        <Grid item xs={12} sm={4}>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "18px", sm: "20px", md: "22px" },
              fontWeight: "bold",
              mb: "10px",
            }}
          >
            USEFUL LINKS
          </Typography>
          {[
            "User Terms & Condition",
            "User Privacy Policy",
            "User Agreement",
          ].map((text, index) => (
            <Typography
              key={index}
              sx={{ fontSize: { xs: "16px", md: "18px" }, mb: "7px" }}
            >
              {text}
            </Typography>
          ))}
        </Grid>

        {/* Contact Section */}
        <Grid item xs={12} sm={4}>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "18px", sm: "20px", md: "22px" },
              fontWeight: "bold",
              mb: "10px",
            }}
          >
            CONTACT US
          </Typography>
          <Typography sx={{ fontSize: { xs: "16px", md: "18px" }, mb: "7px" }}>
            +91 9087654321
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "18px" },
              mb: "7px",
              wordWrap: "break-word",
            }}
          >
            info@triangleinvestment.com
          </Typography>
          <Typography sx={{ fontSize: { xs: "16px", md: "18px" }, mb: "7px" }}>
            Address
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;

// import React from "react";
// import { Grid, Box, Typography, Link } from "@mui/material";
// // import { Link } from "react-router-dom";
// import iconImage from "../../assets/icon.png";

// const Footer = () => {
//   return (
//     // <Box
//     //   component="footer"
//     //   sx={{
//     //     display: "flex",
//     //     justifyContent: "space-between",
//     //     color: "#ffffff",
//     //     padding: { xs: "40px 20px", md: "70px" }, // Responsive padding
//     //     height: { xs: "auto", md: "35vh" }, // Height adjusts based on screen size
//     //   }}
//     // >
//     //   {/* Left Placeholder */}
//     //   <Box
//     //     sx={{
//     //       // width: { xs: "0", md: "35%" }, // Hidden for small screens
//     //       // backgroundColor: "#d3d3d3",
//     //       width: { xs: "90%", sm: "35%" },
//     //       height: { xs: "250px", sm: "100%" },
//     //       paddingLeft: { xs: "20px", md: "55px" },
//     //     }}
//     //   >
//     //     <Box
//     //       sx={{
//     //         width: { xs: "90%", sm: "245.93px" },
//     //         height: { xs: "250px", sm: "254.84px" },
//     //         backgroundImage: `url(${iconImage})`,
//     //         backgroundSize: { xs: "contain", sm: "cover" },
//     //         backgroundPosition: "center",
//     //         backgroundRepeat: "no-repeat",
//     //       }}
//     //     />
//     //   </Box>

//     //   {/* Footer Content */}
//     //   <Grid
//     //     container
//     //     spacing={1}
//     //     sx={{
//     //       width: { xs: "100%", md: "55%" },
//     //       // height: { md: "65vh" },
//     //       margin: 0,
//     //       rowGap: "10px",
//     //     }}
//     //   >
//     //     {/* Info Section */}
//     //     <Grid item xs={12} sm={4}>
//     //       <Typography
//     //         variant="h6"
//     //         sx={{ fontSize: "22px", marginBottom: "10px", fontWeight: "bold" }}
//     //       >
//     //         INFO
//     //       </Typography>
//     //       <Box
//     //         sx={{
//     //           listStyleType: "none",
//     //           padding: 0,
//     //         }}
//     //       >
//     //         <li style={{ paddingBottom: "10px" }}>
//     //           <Link
//     //             href="/"
//     //             color="inherit"
//     //             underline="hover"
//     //            sx={{ fontSize: { xs: "16px", md: "18px" }, fontWeight: "600" }}
//     //           >
//     //             Home
//     //           </Link>
//     //         </li>
//     //         <li style={{ paddingBottom: "10px" }}>
//     //           <Link
//     //             href="/about"
//     //             color="inherit"
//     //             underline="hover"
//     //             sx={{
//     //               fontSize: "18px",
//     //               fontWeight: "600",
//     //             }}
//     //           >
//     //             About Us
//     //           </Link>
//     //         </li>
//     //         <li style={{ paddingBottom: "10px" }}>
//     //           <Link
//     //             href="/invest"
//     //             color="inherit"
//     //             underline="hover"
//     //             sx={{
//     //               fontSize: "18px",
//     //               fontWeight: "600",
//     //             }}
//     //           >
//     //             Invest
//     //           </Link>
//     //         </li>
//     //         <li style={{ paddingBottom: "10px" }}>
//     //           <Link
//     //             href="/blogs"
//     //             color="inherit"
//     //             underline="hover"
//     //             sx={{
//     //               fontSize: "18px",
//     //               fontWeight: "600",
//     //             }}
//     //           >
//     //             Blogs
//     //           </Link>
//     //         </li>
//     //         <li style={{ paddingBottom: "10px" }}>
//     //           <Link
//     //             href="/contact"
//     //             color="inherit"
//     //             underline="hover"
//     //             sx={{
//     //               fontSize: "18px",
//     //               fontWeight: "600",
//     //             }}
//     //           >
//     //             Contact Us
//     //           </Link>
//     //         </li>
//     //       </Box>
//     //       {/* <Typography sx={{ fontSize: "18px", margin: "7px 0px" }}>
//     //         Home
//     //       </Typography>
//     //       <Typography sx={{ fontSize: "18px", margin: "7px 0px" }}>
//     //         About Us
//     //       </Typography> */}
//     //       {/* <Typography sx={{ fontSize: "18px", margin: "7px 0px" }}>
//     //         Invest
//     //       </Typography>
//     //       <Typography sx={{ fontSize: "18px", margin: "7px 0px" }}>
//     //         Blogs
//     //       </Typography>
//     //       <Typography sx={{ fontSize: "18px", margin: "7px 0px" }}>
//     //         Contact Us
//     //       </Typography> */}
//     //     </Grid>

//     //     {/* Useful Links Section */}
//     //     <Grid item xs={12} sm={4}>
//     //       <Typography
//     //         variant="h6"
//     //         sx={{ fontSize: "22px", marginBottom: "10px", fontWeight: "bold" }}
//     //       >
//     //         USEFUL LINKS
//     //       </Typography>
//     //       <Typography sx={{ fontSize: "18px", margin: "7px 0px" }}>
//     //         User Terms & Condition
//     //       </Typography>
//     //       <Typography sx={{ fontSize: "18px", margin: "7px 0px" }}>
//     //         User Privacy Policy
//     //       </Typography>
//     //       <Typography sx={{ fontSize: "18px", margin: "7px 0px" }}>
//     //         User Agreement
//     //       </Typography>
//     //     </Grid>

//     //     {/* Contact Section */}
//     //     <Grid item xs={12} sm={4}>
//     //       <Typography
//     //         variant="h6"
//     //         sx={{ fontSize: "22px", marginBottom: "10px", fontWeight: "bold" }}
//     //       >
//     //         CONTACT US
//     //       </Typography>
//     //       <Typography sx={{ fontSize: "18px", margin: "7px 0px" }}>
//     //         +91 9087654321
//     //       </Typography>
//     //       <Typography
//     //         sx={{
//     //           fontSize: "18px",
//     //           margin: "7px 0px",
//     //           wordWrap: "break-word", // Allow text to wrap within its container
//     //           whiteSpace: "normal",
//     //         }}
//     //       >
//     //         info@triangleinvestment.com
//     //       </Typography>
//     //       <Typography sx={{ fontSize: "18px", margin: "7px 0px" }}>
//     //         address
//     //       </Typography>
//     //     </Grid>
//     //   </Grid>
//     // </Box>

//     <Box
//       component="footer"
//       sx={{
//         display: "flex",
//         flexDirection: { xs: "column", md: "row" }, // Column for mobile, row for larger screens
//         justifyContent: { md: "space-between" }, // Space between elements on larger screens
//         color: "#ffffff",
//         padding: { xs: "40px 20px", sm: "50px 40px", md: "70px" },
//         height: { xs: "auto", md: "35vh" }, // Responsive height
//         gap: { xs: "20px", md: "0" }, // Spacing for stacked layout on mobile
//       }}
//     >
//       {/* Left Placeholder */}
//       <Box
//         sx={{
//           width: { xs: "100%", sm: "35%" },
//           display: { xs: "flex", md: "block" }, // Flex for centered on mobile
//           justifyContent: "center",
//           paddingLeft: { xs: "0", md: "55px" },
//         }}
//       >
//         <Box
//           sx={{
//             width: { xs: "80%", sm: "245.93px" },
//             height: { xs: "200px", sm: "254.84px" },
//             backgroundImage: `url(${iconImage})`,
//             backgroundSize: { xs: "contain", sm: "cover" },
//             backgroundPosition: "center",
//             backgroundRepeat: "no-repeat",
//           }}
//         />
//       </Box>

//       {/* Footer Content */}
//       <Grid
//         container
//         spacing={2}
//         sx={{
//           width: { xs: "100%", md: "60%" },
//           margin: 0,
//           rowGap: "10px",
//         }}
//       >
//         {/* Info Section */}
//         <Grid item xs={12} sm={4}>
//           <Typography
//             variant="h6"
//             sx={{
//               fontSize: { xs: "18px", sm: "20px", md: "22px" },
//               fontWeight: "bold",
//               mb: "10px",
//             }}
//           >
//             INFO
//           </Typography>
//           <Box sx={{ listStyleType: "none", padding: 0 }}>
//             {["Home", "About Us", "Invest", "Plans", "Contact Us"].map(
//               (text, index) => (
//                 <li key={index} style={{ paddingBottom: "10px" }}>
//                   {text === "About Us" ? (
//                     <Link
//                       href="/about"
//                       color="inherit"
//                       underline="hover"
//                       sx={{
//                         fontSize: { xs: "16px", md: "18px" },
//                         fontWeight: "600",
//                       }}
//                     >
//                       {text}
//                     </Link>
//                   ) : text === "Contact Us" ? (
//                     <Link
//                       href="/contact"
//                       color="inherit"
//                       underline="hover"
//                       sx={{
//                         fontSize: { xs: "16px", md: "18px" },
//                         fontWeight: "600",
//                       }}
//                     >
//                       {text}
//                     </Link>
//                   ) : (
//                     <Link
//                       href={`/${text.toLowerCase().replace(" ", "")}`}
//                       color="inherit"
//                       underline="hover"
//                       sx={{
//                         fontSize: { xs: "16px", md: "18px" },
//                         fontWeight: "600",
//                       }}
//                     >
//                       {text}
//                     </Link>
//                   )}
//                 </li>
//               )
//             )}
//             {/* {["Home", "About Us", "Invest", "Blogs", "Contact Us"].map(
//               (text, index) => (
//                 <li key={index} style={{ paddingBottom: "10px" }}>
//                   <Link
//                     href={`/${text.toLowerCase().replace(" ", "")}`}
//                     color="inherit"
//                     underline="hover"
//                     sx={{
//                       fontSize: { xs: "16px", md: "18px" },
//                       fontWeight: "600",
//                     }}
//                   >
//                     {text}
//                   </Link>
//                 </li>
//               )
//             )} */}
//           </Box>
//         </Grid>

//         {/* Useful Links Section */}
//         <Grid item xs={12} sm={4}>
//           <Typography
//             variant="h6"
//             sx={{
//               fontSize: { xs: "18px", sm: "20px", md: "22px" },
//               fontWeight: "bold",
//               mb: "10px",
//             }}
//           >
//             USEFUL LINKS
//           </Typography>
//           {[
//             "User Terms & Condition",
//             "User Privacy Policy",
//             "User Agreement",
//           ].map((text, index) => (
//             <Typography
//               key={index}
//               sx={{ fontSize: { xs: "16px", md: "18px" }, mb: "7px" }}
//             >
//               {text}
//             </Typography>
//           ))}
//         </Grid>

//         {/* Contact Section */}
//         <Grid item xs={12} sm={4}>
//           <Typography
//             variant="h6"
//             sx={{
//               fontSize: { xs: "18px", sm: "20px", md: "22px" },
//               fontWeight: "bold",
//               mb: "10px",
//             }}
//           >
//             CONTACT US
//           </Typography>
//           <Typography sx={{ fontSize: { xs: "16px", md: "18px" }, mb: "7px" }}>
//             +91 9087654321
//           </Typography>
//           <Typography
//             sx={{
//               fontSize: { xs: "16px", md: "18px" },
//               mb: "7px",
//               wordWrap: "break-word",
//             }}
//           >
//             info@triangleinvestment.com
//           </Typography>
//           <Typography sx={{ fontSize: { xs: "16px", md: "18px" }, mb: "7px" }}>
//             Address
//           </Typography>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default Footer;

// import React from 'react';
// import './Footer.css';

// const Footer = () => {
//   return (
//     <footer className="footer">
//       <div className="left-placeholder"></div>
//       <div className="footer-content">
//         <div className="info">
//           <h4>INFO</h4>
//           <ul>
//             <li>Home</li>
//             <li>About Us</li>
//             <li>Invest</li>
//             <li>Blogs</li>
//             <li>Contact Us</li>
//           </ul>
//         </div>
//         <div className="links">
//           <h4>USEFUL LINKS</h4>
//           <ul>
//             <li>User Terms & Condition</li>
//             <li>User Privacy Policy</li>
//             <li>User Agreement</li>
//           </ul>
//         </div>
//         <div className="contact">
//           <h4>CONTACT US</h4>
//           <p>+91 9087654321</p>
//           <p>info@triangleinvestment.com</p>
//           <p>address</p>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;
