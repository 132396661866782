import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  // Check for specific routes and set background color
  const bgColor = ["about", "plan", "contact"].some((route) =>
    location.pathname.includes(route)
  )
    ? "#20EDA1"
    : "#101011";

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: bgColor,
          // background: linear-gradient(180deg, #20EDA1 0%, #00FF97 100%);
          padding: { xs: "0px", md: "0px 15px" },
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            sx={{ flexGrow: 1, fontSize: "24px", fontWeight: "600" }}
          >
            LOGO
          </Typography>

          {/* For larger screens */}
          <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: { xs: "none", md: "space-between" }, width:'42%'}}>
            <Button
              color="inherit"
              component={Link}
              to="/"
              sx={{ gap: "50px", fontSize: "16px", fontWeight: "600" }}
            >
              HOME
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/invest"
              sx={{ gap: "20px", fontSize: "16px", fontWeight: "600" }}
            >
              INVEST
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/about"
              sx={{ gap: "20px", fontSize: "16px", fontWeight: "600" }}
            >
              ABOUT US
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/plans"
              sx={{ gap: "20px", fontSize: "16px", fontWeight: "600" }}
            >
              PLANS
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/contact"
              sx={{ gap: "20px", fontSize: "16px", fontWeight: "600" }}
            >
              CONTACT US
            </Button>
          </Box>

          {/* For smaller screens */}
          <IconButton
            color="inherit"
            edge="end"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile view */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button component={Link} to="/">
              <ListItemText primary="HOME" />
            </ListItem>
            <ListItem button component={Link} to="/invest">
              <ListItemText primary="INVEST" />
            </ListItem>
            <ListItem button component={Link} to="/about">
              <ListItemText primary="ABOUT US" />
            </ListItem>
            <ListItem button component={Link} to="/plans">
              <ListItemText primary="PLANS" />
            </ListItem>
            <ListItem button component={Link} to="/contact">
              <ListItemText primary="CONTACT US" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;

// import React from 'react';
// import './Header.css';

// const Header = () => {
//   return (
//     <header className="header">
//       <div className="logo">LOGO</div>
//       <nav className='nav'>
//         <ul>
//           <li><a href="#home">HOME</a></li>
//           <li><a href="#invest-plans">INVEST</a></li>
//           <li><a href="#about">ABOUT US</a></li>
//           <li><a href="#plans">PLANS</a></li>
//           <li><a href="#contact">CONTACT US</a></li>
//         </ul>
//       </nav>
//     </header>
//   );
// };

// export default Header;
