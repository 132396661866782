import React from "react";
import { Box, Typography } from "@mui/material";
import imageUrl from "../../assets/contentSection/Mask group.png";

const CardSection = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom, #59c988, #83eaf1)",
        width: "100%",
        height: { xs: "500px", sm: "700px", md: "837px" },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        marginTop: "100px",
      }}
    >
      {/* Circle Row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          position: "absolute",
          top: { xs: "8%", md: "10%" },
          // px: { xs: 2, sm: 5, md: 10 },
          // padding: '0px 20px'
          // paddingLeft: { xs: 2, sm: 5, md: 30 },
          //
        }}
      >
        {/* Left Side Circles */}
        <Box
          sx={{
            display: "flex",
            gap: { xs: 1, sm: 10, md: 15 },
            paddingLeft: { xs: 1, sm: 5, md: 10 },
          }}
        >
          {[0, 1].map((index) => (
            <Box
              key={index}
              sx={{
                width: { xs: "70px", sm: "90px", md: "128.88px" },
                height: { xs: "70px", sm: "90px", md: "128.88px" },
                backgroundColor: "white",
                borderRadius: "50%",
                overflow: "hidden",
                boxShadow: "0 0 20px rgba(0, 255, 251, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={imageUrl} // Replace imageUrl with the actual image URL or a prop
                alt={`Circle image ${index + 1}`}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ))}
        </Box>

        {/* Right Side Circles */}
        <Box
          sx={{
            display: "flex",
            gap: { xs: 1, sm: 10, md: 15 },
            paddingRight: { xs: 1, sm: 5, md: 10 },
          }}
        >
          {[2, 3].map((index) => (
            <Box
              key={index}
              sx={{
                width: { xs: "70px", sm: "90px", md: "128.88px" },
                height: { xs: "70px", sm: "90px", md: "128.88px" },
                backgroundColor: "white",
                borderRadius: "50%",
                overflow: "hidden",
                boxShadow: "0 0 20px rgba(0, 255, 251, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={imageUrl} // Replace imageUrl with the actual image URL or a prop
                alt={`Circle image ${index + 1}`}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>

      {/* Center Circle */}
      <Box
        sx={{
          width: { xs: "120px", sm: "150px", md: "219px" },
          height: { xs: "120px", sm: "150px", md: "219px" },
          backgroundColor: "white",
          borderRadius: "50%",
          overflow: "hidden", // Ensures the image stays within the circle
          boxShadow: "0 0 20px rgba(0, 255, 251, 0.5)",
          position: "absolute",
          // top: "45%",
          // top: { xs: "100%", sm: "40%", md: "67%" },
          top: { xs: "33%", sm: "33%", md: "33%" },
          transform: "translateY(-50%)",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={imageUrl} // Replace imageUrl with the actual image URL or a prop
          alt={`Circle image`}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensures the image covers the circular area without stretching
          }}
        />
      </Box>

      {/* Info Box */}
      <Box
        sx={{
          backgroundColor: "#b8e6e4",
          width: { xs: "85%", sm: "75%", md: "80%" },
          height: { xs: "200px", sm: "300px", md: "456px" },
          textAlign: "center",
          padding: "20px",
          // marginTop: "190px",
          mt: { xs: 8, sm: 12, md: 30 },
          // p: 2,
          // mt: 8,
          // display: "flex",
          // flexDirection: "column",
          // justifyContent: "center",
          // alignItems: "center",
          // zIndex: 0,
        }}
      >
        <Box
          sx={{
            // marginTop: "90px",
            color: "#333",
            mt: { xs: "50px", sm: "70px", md: "90px" },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "800",
              margin: 0,
              fontSize: { xs: "24px", sm: "32px", md: "40px" },
              lineHeight: { xs: "28px", sm: "36px", md: "48.41px" },
            }}
          >
            SUJIT PATIL
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "600",
              // margin: "5px 0 0",
              fontSize: { xs: "18px", sm: "24px", md: "30px" },
              lineHeight: { xs: "22px", sm: "28px", md: "36.31px" },
            }}
          >
            MAHARASHTRA
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CardSection;

// import React from "react";
// import { Box, Typography } from "@mui/material";
// import imageUrl from "../../assets/contentSection/Mask group.png";

// const CardSection = () => {
//   return (
//     <Box
//       sx={{
//         background: "linear-gradient(to bottom, #59c988, #83eaf1)",
//         width: "100%",
//         // height: "837px",
//         height: { xs: "500px", sm: "700px", md: "837px" },
//         position: "relative",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         overflow: "hidden",
//         marginTop: "100px",
//         // mt: 3,
//       }}
//     >
//       {/* Circle Row */}
//       {/* <Box
//         sx={{
//           display: "flex",
//           justifyContent: "space-around",
//           width: "100%",
//           position: "absolute",
//           // top: "10%",
//           // paddingX: "50px",
//           top: { xs: "8%", md: "10%" },
//           px: { xs: 2, sm: 5 },
//         }}
//       >
//         {[...Array(4)].map((_, index) => (
//           <Box
//             key={index}
//             sx={{
//               width: { xs: "70px", sm: "90px", md: "128.88px" },
//               height: { xs: "70px", sm: "90px", md: "128.88px" },
//               // backgroundColor: "white",
//               borderRadius: "50%",
//               boxShadow: "0 0 20px rgba(0, 255, 251, 0.5)",
//               backgroundImage: `url(${imageUrl})`,
//               // backgroundSize: "52.18px 52.18px", // Set specific dimensions for the image
//               backgroundSize: "contain",
//               backgroundPosition: "center",
//               backgroundRepeat: "no-repeat",
//             }}
//           />
//         ))}
//       </Box> */}

//       {/* <Box
//         sx={{
//           display: "flex",
//           justifyContent: "space-around",
//           width: "100%",
//           position: "absolute",
//           // top: "10%",
//           // paddingX: "50px",
//           top: { xs: "8%", md: "10%" },
//           px: { xs: 2, sm: 5 },
//         }}
//       >
//         {[...Array(4)].map((_, index) => (
//           <Box
//             key={index}
//             sx={{
//               width: { xs: "70px", sm: "90px", md: "128.88px" },
//               height: { xs: "70px", sm: "90px", md: "128.88px" },
//               backgroundColor: "white",
//               borderRadius: "50%",
//               overflow: "hidden", // Ensures the image stays within the circle
//               boxShadow: "0 0 20px rgba(0, 255, 251, 0.5)",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <Box
//               component="img"
//               src={imageUrl} // Replace imageUrl with the actual image URL or a prop
//               alt={`Circle image ${index + 1}`}
//               sx={{
//                 width: "100%",
//                 height: "100%",
//                 objectFit: "cover", // Ensures the image covers the circular area without stretching
//               }}
//             />
//           </Box>
//         ))}
//       </Box> */}

//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "space-between",
//           width: "100%",
//           position: "absolute",
//           top: { xs: "8%", md: "10%" },
//           // px: { xs: 2, sm: 5, md: 10 },
//           // padding: '0px 20px'
//           // paddingLeft: { xs: 2, sm: 5, md: 30 },
//           //
//         }}
//       >
//         {/* Left Side Circles */}
//         <Box
//           sx={{
//             display: "flex",
//             gap: { xs: 1, sm: 10, md: 15 },
//             paddingLeft: { xs: 1, sm: 5, md: 10 },
//           }}
//         >
//           {[0, 1].map((index) => (
//             <Box
//               key={index}
//               sx={{
//                 width: { xs: "70px", sm: "90px", md: "128.88px" },
//                 height: { xs: "70px", sm: "90px", md: "128.88px" },
//                 backgroundColor: "white",
//                 borderRadius: "50%",
//                 overflow: "hidden",
//                 boxShadow: "0 0 20px rgba(0, 255, 251, 0.5)",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <Box
//                 component="img"
//                 src={imageUrl} // Replace imageUrl with the actual image URL or a prop
//                 alt={`Circle image ${index + 1}`}
//                 sx={{
//                   width: "100%",
//                   height: "100%",
//                   objectFit: "cover",
//                 }}
//               />
//             </Box>
//           ))}
//         </Box>

//         {/* Right Side Circles */}
//         <Box
//           sx={{
//             display: "flex",
//             gap: { xs: 1, sm: 10, md: 15 },
//             paddingRight: { xs: 1, sm: 5, md: 10 },
//           }}
//         >
//           {[2, 3].map((index) => (
//             <Box
//               key={index}
//               sx={{
//                 width: { xs: "70px", sm: "90px", md: "128.88px" },
//                 height: { xs: "70px", sm: "90px", md: "128.88px" },
//                 backgroundColor: "white",
//                 borderRadius: "50%",
//                 overflow: "hidden",
//                 boxShadow: "0 0 20px rgba(0, 255, 251, 0.5)",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <Box
//                 component="img"
//                 src={imageUrl} // Replace imageUrl with the actual image URL or a prop
//                 alt={`Circle image ${index + 1}`}
//                 sx={{
//                   width: "100%",
//                   height: "100%",
//                   objectFit: "cover",
//                 }}
//               />
//             </Box>
//           ))}
//         </Box>
//       </Box>

//       {/* Center Circle */}
//       <Box
//         sx={{
//           width: { xs: "120px", sm: "150px", md: "219px" },
//           height: { xs: "120px", sm: "150px", md: "219px" },
//           backgroundColor: "white",
//           borderRadius: "50%",
//           overflow: "hidden", // Ensures the image stays within the circle
//           boxShadow: "0 0 20px rgba(0, 255, 251, 0.5)",
//           position: "absolute",
//           // top: "45%",
//           // top: { xs: "100%", sm: "40%", md: "67%" },
//           top: { xs: "33%", sm: "33%", md: "33%" },
//           transform: "translateY(-50%)",
//           zIndex: 1,
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <Box
//           component="img"
//           src={imageUrl} // Replace imageUrl with the actual image URL or a prop
//           alt={`Circle image`}
//           sx={{
//             width: "100%",
//             height: "100%",
//             objectFit: "cover", // Ensures the image covers the circular area without stretching
//           }}
//         />
//       </Box>

//       {/* Info Box */}
//       <Box
//         sx={{
//           backgroundColor: "#b8e6e4",
//           width: { xs: "85%", sm: "75%", md: "80%" },
//           height: { xs: "200px", sm: "300px", md: "456px" },
//           textAlign: "center",
//           padding: "20px",
//           // marginTop: "190px",
//           mt: { xs: 8, sm: 12, md: 30 },
//           // p: 2,
//           // mt: 8,
//           // display: "flex",
//           // flexDirection: "column",
//           // justifyContent: "center",
//           // alignItems: "center",
//           // zIndex: 0,
//         }}
//       >
//         <Box
//           sx={{
//             // marginTop: "90px",
//             color: "#333",
//             mt: { xs: "50px", sm: "70px", md: "90px" },
//           }}
//         >
//           <Typography
//             variant="h5"
//             sx={{
//               fontWeight: "800",
//               margin: 0,
//               fontSize: { xs: "24px", sm: "32px", md: "40px" },
//               lineHeight: { xs: "28px", sm: "36px", md: "48.41px" },
//             }}
//           >
//             SUJIT PATIL
//           </Typography>
//           <Typography
//             variant="body1"
//             sx={{
//               fontWeight: "600",
//               // margin: "5px 0 0",
//               fontSize: { xs: "18px", sm: "24px", md: "30px" },
//               lineHeight: { xs: "22px", sm: "28px", md: "36.31px" },
//             }}
//           >
//             MAHARASHTRA
//           </Typography>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default CardSection;

// import React from "react";
// import './CardSection.css'

// const CardSection = () => {
//   return (
//     <div className="card-container">
//       <div className="circles">
//         <div className="circle"></div>
//         <div className="circle"></div>
//         <div className="circle"></div>
//         <div className="circle"></div>
//         <div className="center-circle"></div>
//       </div>
//       <div className="info-box name-box">
//         <div className="name-detail">
//           <h1>SUJIT PATIL</h1>
//           <p>MAHARASTRA</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CardSection;
