import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import contentImage from "../../assets/contentSection/Mask group.png";
import circleImage1 from "../../assets/contentSection/image 1.png";
import circleImage2 from "../../assets/contentSection/image 2.png";
import circleImage3 from "../../assets/contentSection/image 3.png";
import circleImage4 from "../../assets/contentSection/image 4.png";

// Custom button style to mimic the gradient effect and required dimensions
const GradientButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(90deg, #101011 0%, #21EDA2 100%)",
  color: "#FFFFFF",
  borderRadius: "54.5px",
  padding: "10px 20px",
  width: "100%", // Width specified in the description
  height: "103px", // Height specified in the description
  justifyContent: "end",
  columnGap: "30px",
  display: "flex",
  alignItems: "center",
  opacity: 1, // You can change this from 0 to make it visible
  transition: "background 0.3s ease-in-out",
  // "&:hover": {
  //   background: "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(40,255,200,1) 100%)", // Slightly different hover gradient
  // },
  [theme.breakpoints.down("md")]: {
    padding: "10px 15px",
    width: "100%", // Adjust for small screens
    height: "auto",
  },
}));

const Circle = styled(Box)(({ theme, imageUrl }) => ({
  width: "86px",
  height: "86px",
  backgroundColor: "#AAEED6",
  borderRadius: "50%",
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: "52.18px 52.18px", // Set specific dimensions for the image
  // backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  [theme.breakpoints.down("md")]: {
    width: "86px",
    height: "80px",
    backgroundSize: "52px 52px", // Adjust size for smaller screens if needed
  },
}));

const ContentSection = () => {
  const theme = useTheme(); // Access the theme
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // Use breakpoints correctly

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      {/* Title */}
      <Typography
        variant="h4"
        align="center"
        fontWeight={"600"}
        fontSize={"26px"}
        gutterBottom
      >
        Why Should You Choose Triangle
      </Typography>

      {/* Main Section */}
      <Grid container spacing={4} alignItems="center" paddingTop={1}>
        {/* Left: Gradient Buttons */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <GradientButton>
              <Typography
                variant="body1"
                sx={{
                  width: "85%",
                  fontWeight: "600",
                  fontSize: "24px",
                  textAlign: "right",
                }}
              >
                One single destination for your entire investment needs
              </Typography>
              <Circle imageUrl={circleImage1} />
            </GradientButton>
            <GradientButton>
              <Typography
                variant="body1"
                sx={{
                  width: "70%",
                  fontWeight: "600",
                  fontSize: "24px",
                  textAlign: "right",
                }}
              >
                Get your own Personal Investment Coach
              </Typography>
              <Circle imageUrl={circleImage2} />
            </GradientButton>
            <GradientButton>
              <Typography
                variant="body1"
                sx={{
                  width: "55%",
                  fontWeight: "600",
                  fontSize: "24px",
                  textAlign: "right",
                }}
              >
                World class in-house Research Team
              </Typography>
              <Circle imageUrl={circleImage3} />
            </GradientButton>
            <GradientButton>
              <Typography
                variant="body1"
                sx={{
                  width: "50%",
                  fontWeight: "600",
                  fontSize: "24px",
                  textAlign: "right",
                }}
              >
                Most trustable platform
              </Typography>
              <Circle imageUrl={circleImage4} />
            </GradientButton>
          </Box>
        </Grid>

        {/* Right: Illustration (Placeholder for the person sitting) */}
        <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
          <Box
            component="img"
            src={contentImage}
            alt="Illustration"
            sx={{
              width: isSmallScreen ? "80%" : "80%",
              // width: "100%",
              height: "622px",
              // borderRadius: "10px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentSection;

// import React from "react";
// import {
//   Container,sx={{ width: "721px", height: "103px" }}
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
// } from "@mui/material";
// import "./ContentSection.css";
// import chair_image from "../assets/chair_image.png";

// const ContentSection = () => {
//   return (
//     <Container>
//       <h1 className="content-section-heading">Content Section</h1>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={6} className=" content-section">
//           <ToggleButtonGroup orientation="vertical">
//             <ToggleButton value="lorem picsum">
//               <div className=" content-toggle-wrapper">
//                 <div className=" content-toggle">
//                   <div className=" content-toggle-track">
//                     <div className=" content-toggle-thumb"></div>
//                   </div>
//                   <span className=" content-toggle-text">lorem picsum</span>
//                 </div>
//               </div>
//             </ToggleButton>
//             <ToggleButton value="lorem picsum">
//               <div className=" content-toggle-wrapper">
//                 <div className=" content-toggle">
//                   <div className=" content-toggle-track">
//                     <div className=" content-toggle-thumb"></div>
//                   </div>
//                   <span className=" content-toggle-text">lorem picsum</span>
//                 </div>
//               </div>
//             </ToggleButton>
//             <ToggleButton value="lorem picsum">
//               <div className=" content-toggle-wrapper">
//                 <div className=" content-toggle">
//                   <div className=" content-toggle-track">
//                     <div className=" content-toggle-thumb"></div>
//                   </div>
//                   <span className=" content-toggle-text">lorem picsum</span>
//                 </div>
//               </div>
//             </ToggleButton>
//             <ToggleButton value="lorem picsum">
//               <div className=" content-toggle-wrapper">
//                 <div className=" content-toggle">
//                   <div className=" content-toggle-track">
//                     <div className=" content-toggle-thumb"></div>
//                   </div>
//                   <span className=" content-toggle-text">lorem picsum</span>
//                 </div>
//               </div>
//             </ToggleButton>
//           </ToggleButtonGroup>
//         </Grid>

//         <Grid item xs={12} sm={6}>
//           <img
//             src={chair_image}
//             alt="character"
//             style={{
//               float: "right",
//               marginRight: 20,
//               height: "400px",
//               width: "300px",
//             }}
//           />
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default ContentSection;

// import React from "react";

// const ContentSection = () => {
//   return (
//     <div className="content-section">
//       <h4>Content-Placeholder</h4>
//       <div className="steps">
//         <div className="step">Lorem Ipsum</div>
//         <div className="step">Lorem Ipsum</div>
//         <div className="step">Lorem Ipsum</div>
//         <div className="step">Lorem Ipsum</div>
//       </div>
//       <div className="placeholder"></div>
//     </div>
//   );
// };

// export default ContentSection;
