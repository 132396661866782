import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import computer2 from "../../assets/Mask group (2).png";

const InfoSectionAbout = () => {
  return (
    <Box sx={{ mt: 4, mb: 4, padding: { xs: "0px 30px", md: "0px 60px" } }}>
      <Grid
        container
        spacing={10}
        // alignItems="center"
        justifyContent={"space-between"}
        paddingTop={5}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: 0, md: 8 },
        }}
      >
        {/* Left Side: Image */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              paddingLeft: "10px",
              position: "relative",
            }}
          >
            <Box
              component="img"
              src={computer2}
              alt="Illustration"
              sx={{
                borderRadius: "10px",
                width: "100%",
                maxWidth: "556.78px",
                height: "auto",
              }}
            />
          </Box>

          {/* White box with text inside */}
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              padding: "10px",
              width: "100%",
              maxWidth: "612px",
              // height: "100%",
              borderRadius: "13px",
            }}
          >
            <Typography
              variant="caption"
              align="left"
              sx={{
                display: "block",
                color: "#101011",
                maxWidth: "580px",
                fontSize: "22px",
                fontWeight: "600",
                paddingLeft: "10px",
                // lineHeight:'26.63px'
              }}
            >
              *Have your PAN, Bank, and Aadhaar information handy
            </Typography>
          </Box>
        </Grid>

        {/* Right Side: Steps */}
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 55,
              // alignItems: "center",
              // justifyContent:"space-between"
            }}
          >
            <Typography
              variant="h5"
              fontWeight={"600"}
              fontSize={"34px"}
              gutterBottom
            >
              About Our Company
            </Typography>
            {/* Left Placeholder */}
            <Box
              sx={{
                width: "293px",
                height: "90px",
                backgroundColor: "#D9D9D9",
              }}
            ></Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoSectionAbout;
