import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import computer2 from "../../assets/Mask group (2).png";

// Custom styled components for buttons with gradient background
const GradientButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(90deg, #018354 0%, #19E6AF 100%)",
  color: "#101011",
  borderRadius: "50px",
  padding: "10px 20px",
  width: "100%",
  maxWidth: "450px",
  height: "70px",
  justifyContent: "flex-start",
  display: "flex",
  alignItems: "center",
  // fontSize: "18px",
  transition: "background 0.3s ease-in-out",
  // overflow: "hidden",
  "&:hover": {
    background: "linear-gradient(90deg, #018354 0%, #28FFC8 100%)",
  },
  [theme.breakpoints.down("sm")]: {
    height: "50px",
    maxWidth: "100%",
    padding: "8px 15px",
  },
}));

// Circle with number inside
const StepCircle = styled(Box)(({ theme }) => ({
  width: "65px",
  height: "66px",
  backgroundColor: "#7CE5C0",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#018354", // Text color inside the circle
  fontSize: "50px",
  fontWeight: "700",
  marginLeft: "-65px", // Half of the width of the circle to overlap the button

  // marginRight: "15px", // Gap between circle and text

  position: "relative",

  zIndex: 2,
  [theme.breakpoints.down("sm")]: {
    width: "40px",
    height: "40px",
    fontSize: "18px",
    marginLeft: "-45px",
    // marginRight: "10px",
  },
}));

// Circle background for dark shadow effect
const StepCircle1 = styled(Box)(({ theme }) => ({
  width: "65px",
  height: "66px",
  backgroundColor: "#101011",
  borderRadius: "50%",
  // marginLeft: "-40px", // Half of the width of the circle to overlap the button
  // marginRight: "-20px", // Gap between circle and text

  position: "absolute",
  left: "-20px",
  zIndex: 1,

  [theme.breakpoints.down("sm")]: {
    width: "40px",
    height: "40px",
    // marginLeft: "-15px",
    // marginRight: "10px",

    left: "-12px",
  },
}));

const StepText = styled("span")(({ theme }) => ({
  // fontSize: "26px",
  fontWeight: "600",
  color: "#101011", // Text color inside button
  marginLeft: "40px", // Ensures text is positioned after the circle
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
    marginLeft: "40px",
  },
}));

// Vertical line between circles
const StepConnector = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "66px", // Aligns with bottom of circle
  left: "-11px", // Centered with the circle
  width: "2px",
  height: "40px", // Height between circles
  backgroundColor: "#7CE5C0",
  zIndex: 1,
  [theme.breakpoints.down("sm")]: {
    top: "40px",
    left: "-10px",
    height: "50px",
  },
}));

const infoSection = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography
        variant="h5"
        align="center"
        fontWeight={"600"}
        fontSize={"26px"}
        gutterBottom
      >
        OUR 10 MIN - PROMISE
      </Typography>
      <Typography
        variant="body2"
        align="center"
        fontWeight={"600"}
        fontSize={"14px"}
        gutterBottom
      >
        GET YOUR ACCOUNT SETUP SUPER FAST WITH US
      </Typography>

      <Grid
        container
        spacing={10}
        alignItems="center"
        justifyContent={"space-between"}
        paddingTop={10}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: 0, md: 8 },
        }}
      >
        {/* Left Side: Image */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              paddingLeft: "10px",
              position: "relative",
            }}
          >
            <Box
              component="img"
              src={computer2}
              // src="https://via.placeholder.com/400"
              alt="Illustration"
              sx={{
                borderRadius: "10px",
                width: "100%",
                maxWidth: "556.78px",
                height: "auto",
              }}
            />
          </Box>
          {/* White box with text inside */}
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              padding: "10px",
              width: "100%",
              maxWidth: "612px",
              height: "100%",
              borderRadius: "13px",
            }}
          >
            <Typography
              variant="caption"
              align="left"
              sx={{
                display: "block",
                color: "#101011",
                maxWidth: "580px",
                fontSize: "22px",
                fontWeight: "600",
                paddingLeft: "10px",
                // lineHeight:'26.63px'
              }}
            >
              *Have your PAN, Bank, and Aadhaar information handy
            </Typography>
          </Box>

          {/* Text below white box */}
          <Typography
            variant="body2"
            align="left"
            sx={{
              mt: 1,
              paddingLeft: "20px",
              width: "100%",
              maxWidth: "580px",
              // height: "42px",
              height: "100%",
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "19.36px",
            }}
          >
            In some cases, the account activation may take up to 72 hours as we
            are dependent on KRAs to process your KYC.
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              position: "relative",
            }}
          >
            {/* Button with steps */}
            {[
              { step: 1, label: "PAN Details" },
              { step: 2, label: "KYC Details" },
              { step: 3, label: "Add your nominee" },
              { step: 4, label: "Link your bank" },
              { step: 5, label: "Address" },
            ].map((item, index) => (
              <Box key={index} sx={{ position: "relative" }}>
                <GradientButton>
                  <StepCircle>{item.step}</StepCircle>
                  <StepCircle1 />
                  <StepText
                    sx={{ fontSize: { xs: "15px", sm: "20px", md: "26px" } }}
                  >
                    {item.label}
                  </StepText>
                </GradientButton>
                {index < 4 && <StepConnector />}{" "}
                {/* Adds connector line for all except the last item */}
              </Box>
            ))}
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={5}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <GradientButton>
              <StepCircle>1</StepCircle>
              <StepCircle1></StepCircle1>
              <StepText>PAN Details</StepText>
            </GradientButton>

            <GradientButton>
              <StepCircle>2</StepCircle>
              <StepCircle1></StepCircle1>
              <StepText>KYC Details</StepText>
            </GradientButton>

            <GradientButton>
              <StepCircle>3</StepCircle>
              <StepCircle1></StepCircle1>
              <StepText>Add your nominee</StepText>
            </GradientButton>

            <GradientButton>
              <StepCircle>4</StepCircle>
              <StepCircle1></StepCircle1>
              <StepText>Link your bank</StepText>
            </GradientButton>

            <GradientButton>
              <StepCircle>5</StepCircle>
              <StepCircle1></StepCircle1>
              <StepText>Address</StepText>
            </GradientButton>
          </Box>
        </Grid> */}
        {/* Right Side: Steps */}
        {/* <Grid item xs={12} md={5}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
         
              <GradientButton>
                <StepCircle>1</StepCircle>{" "}
                <span style={{ fontSize: "26px", fontWeight: "600" }}>
                  PAN Details
                </span>
              </GradientButton>
          

            <GradientButton>
              <StepCircle>2</StepCircle>{" "}
              <span style={{ fontSize: "26px", fontWeight: "600" }}>
                KYC Details
              </span>
            </GradientButton>

            <GradientButton>
              <StepCircle>3</StepCircle>{" "}
              <span style={{ fontSize: "26px", fontWeight: "600" }}>
                Add your nominee
              </span>
            </GradientButton>

            <GradientButton>
              <StepCircle>4</StepCircle>{" "}
              <span style={{ fontSize: "26px", fontWeight: "600" }}>
                Link your bank
              </span>
            </GradientButton>

            <GradientButton>
              <StepCircle>5</StepCircle>{" "}
              <span style={{ fontSize: "26px", fontWeight: "600" }}>
                Address
              </span>
            </GradientButton>
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default infoSection;

// import React from "react";
// import {
//   Container,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
// } from "@mui/material";
// import computer2 from "../assets/computer2.png";
// import "./InfoSection.css";

// const InfoSection = () => {
//   return (
//     <Container>
//       <h1 className="content-section-heading"> Information Section</h1>
//       <Grid container spacing={50}>
//         <Grid item xs={12} sm={6}>
//           <img
//             src={computer2}
//             alt="character"
//             style={{
//               float: "left",
//               marginRight: 20,
//               height: "400px",
//               width: "300px",
//             }}
//           />
//         </Grid>

//         <Grid item xs={12} sm={6} className="info-section">
//           <ToggleButtonGroup orientation="vertical">
//             <ToggleButton value="PAN Details">
//               <div className="info-toggle-wrapper">
//                 <div className="info-toggle">
//                   <div className="info-toggle-track">
//                     <div className="info-toggle-thumb"></div>
//                   </div>
//                   <span className="info-toggle-text">PAN Details</span>
//                 </div>
//               </div>
//             </ToggleButton>
//             <ToggleButton value="KYC Details">
//               <div className="info-toggle-wrapper">
//                 <div className="info-toggle">
//                   <div className="info-toggle-track">
//                     <div className="info-toggle-thumb"></div>
//                   </div>
//                   <span className="info-toggle-text">KYC Details</span>
//                 </div>
//               </div>
//             </ToggleButton>
//             <ToggleButton value="Add your nominee">
//               <div className="info-toggle-wrapper">
//                 <div className="info-toggle">
//                   <div className="info-toggle-track">
//                     <div className="info-toggle-thumb"></div>
//                   </div>
//                   <span className="info-toggle-text">Add your nominee</span>
//                 </div>
//               </div>
//             </ToggleButton>
//             <ToggleButton value="Link your bank">
//               <div className="info-toggle-wrapper">
//                 <div className="info-toggle">
//                   <div className="info-toggle-track">
//                     <div className="info-toggle-thumb"></div>
//                   </div>
//                   <span className="info-toggle-text">Link your bank</span>
//                 </div>
//               </div>
//             </ToggleButton>
//             <ToggleButton value="Address">
//               <div className="info-toggle-wrapper">
//                 <div className="info-toggle">
//                   <div className="info-toggle-track">
//                     <div className="info-toggle-thumb"></div>
//                   </div>
//                   <span className="info-toggle-text">Address</span>
//                 </div>
//               </div>
//             </ToggleButton>
//           </ToggleButtonGroup>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default InfoSection;

// import React from "react";

// const InfoSection = () => {
//   return (
//     <div className="info-section">
//       <h4>Info-Placeholder</h4>
//       <div className="info-image-placeholder"></div>
//       <div className="info-content">
//         <div className="info-box">Pan Details</div>
//         <div className="info-box">KYC Details</div>
//         <div className="info-box">Add your nominee</div>
//         <div className="info-box">Link your bank</div>
//         <div className="info-box">Adreess</div>
//       </div>
//     </div>
//   );
// };

// export default InfoSection;
