import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import ContentSection from "../../components/contentSection/ContentSection";
import InfoSection from "../../components/infoSection/InfoSection";
import MobileSection from "../../components/mobileSection/MobileSection";
import CardSection from "../../components/cardSection/CardSection";
import graphImage from "../../assets/graph1.png";
import fileImage from "../../assets/file.png";

const HomePage = () => {
  return (
    // <Container>
    <Box
      component="main"
      sx={{
        color: "#ffffff",
        padding: { xs: "0px 20px", md: "0px 40px" },
      }}
    >
      {/* Graph Section */}
      <Box
        sx={{
          marginBottom: "66px",
          width: "100%",
          height: "936px",
          backgroundImage: `url(${graphImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      {/* File Section */}
      <Box sx={{ textAlign: "center", marginBottom: "110px" }}>
        <Typography
          variant="h4"
          align="center"
          fontWeight={"600"}
          fontSize={"26px"}
          gutterBottom
        >
          ABOUT OUR COMPANY
        </Typography>
        <Box
          sx={{
            // width: { xs: "90%", sm: "475px" },
            width: { xs: "90%", sm: "530.85px" },
            // height: { xs: "250px", sm: "374px" },
            height: { xs: "250px", sm: "383px" },
            backgroundImage: `url(${fileImage})`,
            backgroundSize: { xs: "contain", sm: "cover" },
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            marginTop: "66px",
            marginLeft: { xs: "30px", sm: "30px" },
          }}
        />
      </Box>

      <ContentSection />
      <InfoSection />
      <MobileSection />
      <CardSection />
    </Box>
    // </Container>
  );
};

export default HomePage;

// import React from "react";
// import "./index.css";
// import ContentSection from "../../components/ContentSection";
// import InfoSection from "../../components/InfoSection";
// import MobileSection from "../../components/MobileSection";
// import CardSection from "../../components/CardSection";

// const HomePage = () => {
//   return (
//     <main className="main-content">
//       <div className="graph-section">
//         <div className="graph-placeholder"></div>
//       </div>
//       <div className="file-section">
//         <h4 style={{textAlign:"center"}}>File-Placeholder</h4>
//         <div className="file-placeholder"></div>
//       </div>
//       <ContentSection/>
//       <InfoSection/>
//       <MobileSection/>
//       <CardSection/>
//     </main>
//   );
// };

// export default HomePage;
