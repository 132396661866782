import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import ContentSection from "../../components/contentSection/ContentSection";
import MobileSection from "../../components/mobileSection/MobileSection";
import CardSection from "../../components/cardSection/CardSection";
import vectorImage from "../../assets/Vector.png";
import GroupImage from "../../assets/Group.png";

import fileImage from "../../assets/file.png";
import InfoSectionAbout from "../../components/infoSectionAbout/InfoSectionAbout";

const AboutPage = () => {
  return (
    // <Container>
    <Box
      component="main"
      sx={{
        color: "#ffffff",
        // padding: { xs: "0px 0px", md: "0px 0px" },
      }}
    >
      {/* 1 Section */}
      <Box
        sx={{
          marginBottom: "50px",
          width: "100%",
          height: "588px",
          backgroundImage: `url(${vectorImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#000000",
            fontWeight: "600",
            fontSize: { xs: "30px", sm: "40px", md: "70px" },
            maxWidth: { xs: "40%", sm: "40%", md: "40%" },
            paddingBottom: "150px",
            paddingLeft: { xs: "46px", sm: "62px", md: "70px" },
          }}
        >
          We are breaking ground with our technology.
        </Typography>
      </Box>

      <InfoSectionAbout />

      <Box
        sx={{
          marginTop: "150PX",
          marginBottom: "150px",
          width: "100%",
          height: "734px",
          backgroundImage: `url(${GroupImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "end",
          justifyContent: "flex-start",
          // paddingLeft: { xs: "16px", sm: "32px", md: "58px" },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#000000",
            fontWeight: "600",
            fontSize: { xs: "30px", sm: "40px", md: "90px" },
            maxWidth: { xs: "40%", sm: "40%", md: "40%" },
            // paddingTop: "250px",
            marginBottom: "35px",
            paddingLeft: { xs: "46px", sm: "62px", md: "98px" },
          }}
        >
          Ready to turn dreams into reality?
        </Typography>
      </Box>

      {/* File Section */}
      <Box
        sx={{
          textAlign: "center",
          marginBottom: "150px",
          padding: { xs: "0px 50px", md: "0px 110px" },
        }}
      >
        <Grid
          // container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            // alignItems: "center",
            // gap: { xs: 0, sm: "15px" },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              fontSize: "50px",
              height: "100%",
              mt: { xs: 4, sm: 5, md: 7 },
            }}
          >
            our mission
          </Typography>
          <Box
            sx={{
              width: { xs: "90%", sm: "530.85px" },
              height: { xs: "250px", sm: "383px" },
              backgroundImage: `url(${fileImage})`,
              backgroundSize: { xs: "contain", sm: "cover" },
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              // marginTop: "50px",
              // marginLeft: { xs: "30px", sm: "30px" },
            }}
          />
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            // alignItems: "center",
            gap: { xs: 0, sm: "150px" },
            marginTop: "50px",
          }}
        >
          <Box
            sx={{
              width: { xs: "90%", sm: "530.85px" },
              height: { xs: "250px", sm: "383px" },
              backgroundImage: `url(${fileImage})`,
              backgroundSize: { xs: "contain", sm: "cover" },
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              // marginTop: "50px",
              // marginLeft: { xs: "30px", sm: "30px" },
            }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              fontSize: "50px",
              height: "100%",
              mt: { xs: 4, sm: 5, md: 7 },
            }}
          >
            our vission
          </Typography>
        </Grid>
      </Box>
      <Box
        sx={{
          padding: { xs: "0px 20px", md: "0px 40px" },
        }}
      >
        <CardSection />
      </Box>
    </Box>
    // </Container>
  );
};

export default AboutPage;
