import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import MobileSection from "../../components/mobileSection/MobileSection";
import CardSection from "../../components/cardSection/CardSection";
import graphImage from "../../assets/graph.png";
import fileImage from "../../assets/file.png";
// import fileImage1 from "../../assets/file2.png";

const InvestPage = () => {
  return (
    // <Container>
    <Box
      component="main"
      sx={{
        color: "#ffffff",
        padding: { xs: "0px 20px", md: "0px 40px" },
      }}
    >
      {/* Graph Section */}
      <Box
        sx={{
          marginBottom: "66px",
          width: "100%",
          // height: "150vh",
          height: "936px",
          backgroundImage: `url(${graphImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#23ECA2",
            fontWeight: "600",
            fontSize: { xs: "30px", sm: "40px", md: "90px" },
            maxWidth: { xs: "40%", sm: "40%", md: "40%" },
            paddingTop: "230px",
            paddingLeft: { xs: "46px", sm: "62px", md: "110px" },
          }}
        >
          Trust with confidence
        </Typography>
      </Box>

      <MobileSection />

      {/* File Section */}
      <Box
        sx={{ textAlign: "center", marginBottom: "150px", marginTop: "66px" }}
      >
        <Typography
          variant="h4"
          align="center"
          fontWeight={"600"}
          fontSize={"60px"}
          gutterBottom
        >
          Investment Options
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            alignItems: "center",
            gap: { xs: 0, sm: "150px" },
            marginTop: "50px",
          }}
        >
          <Box
            sx={{
              // width: { xs: "90%", sm: "475px" },
              width: { xs: "90%", sm: "530.85px" },
              // height: { xs: "250px", sm: "374px" },
              height: { xs: "250px", sm: "383px" },
              backgroundImage: `url(${fileImage})`,
              backgroundSize: { xs: "contain", sm: "cover" },
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              marginTop: "50px",
              marginLeft: { xs: "30px", sm: "30px" },
            }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              lineHeight: "108.92px",
              fontSize: "90px",
              height: "109px",
              mt: { xs: 4, sm: -5, md: -17 },
            }}
          >
            01
          </Typography>
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            alignItems: "center",
            gap: { xs: 0, sm: "15px" },
            marginTop: "50px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              lineHeight: "108.92px",
              fontSize: "90px",
              height: "109px",
              mt: { xs: 4, sm: -5, md: -17 },
            }}
          >
            02
          </Typography>
          <Box
            sx={{
              // width: { xs: "90%", sm: "475px" },
              width: { xs: "90%", sm: "530.85px" },
              // height: { xs: "250px", sm: "374px" },
              height: { xs: "250px", sm: "383px" },
              backgroundImage: `url(${fileImage})`,
              backgroundSize: { xs: "contain", sm: "cover" },
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              marginTop: "50px",
              marginLeft: { xs: "30px", sm: "30px" },
            }}
          />
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            alignItems: "center",
            gap: { xs: 0, sm: "150px" },
            marginTop: "50px",
          }}
        >
          <Box
            sx={{
              // width: { xs: "90%", sm: "475px" },
              width: { xs: "90%", sm: "530.85px" },
              // height: { xs: "250px", sm: "374px" },
              height: { xs: "250px", sm: "383px" },
              backgroundImage: `url(${fileImage})`,
              backgroundSize: { xs: "contain", sm: "cover" },
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              marginTop: "50px",
              marginLeft: { xs: "30px", sm: "30px" },
            }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              lineHeight: "108.92px",
              fontSize: "90px",
              height: "109px",
              mt: { xs: 4, sm: -5, md: -17 },
            }}
          >
            03
          </Typography>
        </Grid>
      </Box>

      <CardSection />
    </Box>
    // </Container>
  );
};

export default InvestPage;
